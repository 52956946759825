import Image from "next/image";
import { getReadableImageUrl } from "@/lib/directus";

interface FeaturedLogosSectionData {
  image: {
    alt: string;
      image_file: {
        image: {
          id: string;
          filename_download: string;
        };
      };
  };
}

export const fields = [
  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download",
] as const;

interface FeaturedLogosSectionProps {
  data: FeaturedLogosSectionData;
}

export default function FeaturedLogosSection({ data }: FeaturedLogosSectionProps) {
  return (
    <section id="BrandsSection" className="bg-[#f9fafa]">
      <Image
        src={getReadableImageUrl(
          data?.image?.image_file?.image?.id || "",
          data?.image?.image_file?.image?.filename_download || ""
        )}
        alt="Featured Logos"
        width={1920}
        height={1080}
        className="py-[27px] w-[90%] max-w-[1080px] mx-auto"
        quality={95}
        loading={"lazy"}
      />
    </section>
  );
}
