import Button from "@/components/ui/button";
import Link from "@/components/ui/Link";
import { sanitizeHTML } from "@/lib/utils";

interface DrDeSilvaOverviewSectionData {
  content_collection: {
    id: number;
    site_content_collections_id: {
      collection: {
        item: {
          title?: string;
          text?: string;
        };
      }[];
    };
  }[];
  heading: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  button: {
    href: string;
    label: string;
  };
  text: {
    text?: string;
  };
}

interface DrDeSilvaOverviewSectionProps {
  data?: DrDeSilvaOverviewSectionData;
}

export const fields = [
  "content_collection.site_content_collections_id.collection.item:site_content.title",
  "content_collection.site_content_collections_id.collection.item:site_content.text",

  "heading.text",
  "heading.title",

  "subheading.text",
  "subheading.title",

  "button.href",
  "button.label",

  "text.text",
] as const;

export default function DrDeSilvaOverviewSection({
  data,
}: DrDeSilvaOverviewSectionProps) {
  return (
    <section
      id="DrDeSilvaOverviewSection"
      className="py-12 md:py-14 lg:py-16 leading-normal"
    >
      <div className="container">
        <div className="max-w-[1400px] xs:w-[90%] w-full mx-auto">
          {data?.subheading?.title && (
            <div className="text-center mb-12">
              <h4
                className="font-freightBig text-2xl md:text-2xl lg:text-3xl text-black leading-tight"
                dangerouslySetInnerHTML={sanitizeHTML(
                  data?.subheading?.title || data?.subheading?.text || ""
                )}
              />
            </div>
          )}
          {data?.heading?.title && (
            <div className="text-center mb-12">
              <h2
                className="font-freightBig text-4xl md:text-5xl lg:text-6xl text-[#655e5d] leading-tight"
                dangerouslySetInnerHTML={sanitizeHTML(
                  data?.heading?.title || data?.heading?.text || ""
                )}
              />
            </div>
          )}
          <div>
            {data?.text?.text && (
              <div
                className="text-black font-aileron text-base leading-relaxed text-center mb-12 prose prose-p:mb-4 max-w-none"
                dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")}
              />
            )}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
            {data?.content_collection?.map((column, index) => {
              // Find title and text from collection array
              const titleItem =
                column?.site_content_collections_id?.collection?.find(
                  (item) => item?.item?.title
                );
              const textItem =
                column?.site_content_collections_id?.collection?.find(
                  (item) => item?.item?.text
                );
              return (
                <div
                  key={column?.id}
                  className={`${
                    index === data?.content_collection?.length - 1 &&
                    data?.content_collection?.length % 2 === 1
                      ? "lg:col-span-2 lg:mx-auto lg:max-w-[50%]"
                      : ""
                  }`}
                >
                  <h3 className="font-freightBig text-3xl md:text-4xl text-[#332f2c] mb-4 text-center font-bold">
                    {titleItem?.item?.title}
                  </h3>
                  <div
                    className="text-black font-aileron text-base leading-relaxed [&>ul]:list-disc [&>ul]:pl-4 [&>ul]:mb-4 prose prose-p:mb-4 max-w-none [&>ul]:text-black [&>ul>li::marker]:text-black"
                    dangerouslySetInnerHTML={sanitizeHTML(
                      textItem?.item?.text || ""
                    )}
                  />
                </div>
              );
            })}
          </div>
          <div className="text-center">
            {data?.button && (
              <Button asChild>
                <Link href={data?.button?.href || "#"}>
                  {data?.button?.label || ""}
                </Link>
              </Button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
