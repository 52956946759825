import Image from "next/image";
import Link from "@/components/ui/Link";
import { sanitizeHTML } from "@/lib/utils";
import BACarousel from "@/components/ui/BACarousel";

interface Images {
  images_id: {
    alt: string;
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
  };
}

interface FacialSurgeryResultsShowcaseData {
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  images?: Images[];
  button?: {
    href: string;
    label: string;
  };
}

interface FacialSurgeryResultsShowcaseProps {
  data?: FacialSurgeryResultsShowcaseData;
}

export const fields = [
  "heading.text",
  "heading.title",

  "subheading.text",
  "subheading.title",
  "text.text",

  "images.images_id.alt",
  "images.images_id.image_file.image.id",
  "images.images_id.image_file.image.filename_download",

  "button.href",
  "button.label",
] as const;

export default function FacialSurgeryResultsShowcase({
  data,
}: FacialSurgeryResultsShowcaseProps) {
  return (
    <section
      id="facial-surgery-results-showcase" 
      className="w-full bg-[#f3f1f1] py-[75px]"
    >
      <div className="max-w-[1316px] mx-auto">
        <div className="flex flex-col items-center text-center container">
          <span
            className="font-aileron font-bold uppercase text-xs tracking-[2px] text-black mb-[18px]"
            dangerouslySetInnerHTML={sanitizeHTML(
              data?.subheading?.text || data?.subheading?.title || ""
            )}
          />
          <h2
            className="font-freightBig text-[30px] md:text-[44px] text-black leading-tight mb-[15px]"
            dangerouslySetInnerHTML={sanitizeHTML(
              data?.heading?.title || data?.heading?.text || ""
            )}
          />
          <div
            className="font-aileron text-base text-black leading-normal mb-[50px] w-full md:w-1/2 mx-auto"
            dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")}
          />
          <div className="w-full ">
            <BACarousel images={data?.images || []} />
          </div>
          <br />
          {data?.button && (
            <Link
              href={data.button.href}
              className="inline-flex items-center justify-center px-[35px] py-4 text-xs font-aileron font-bold uppercase tracking-wider text-[#332f2c] border border-[#b2b2b2] rounded-full transition-all duration-300 hover:bg-[#645e5e] hover:text-white hover:border-black"
            >
              {data.button.label}
            </Link>
          )}
        </div>
      </div>
    </section>
  );
}
