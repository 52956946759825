import Link from "@/components/ui/Link";
import Button from "../ui/button";
import { sanitizeHTML } from "@/lib/utils";
import { getReadableImageUrl } from "@/lib/directus";
import Image from "next/image";

interface DeepPlaneFaceliftSectionData {
  heading: {
    title?: string;
    text?: string;
  };
  text: {
    text?: string;
  };
  video: {
    embed_url?: string;
    embed_title?: string;
  };
  button: {
    label?: string;
    href?: string;
  };
  image?: {
    alt: string;
    image_file: {
      image: {
        id: string;
        filename_download: string;
      };
    };
  };
}

export const fields = [
  "heading.title",
  "heading.text",
  "text.text",
  "video.embed_url",
  "video.embed_title",
  "button.label",
  "button.href",
  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download",
] as const;

interface DeepPlaneFaceliftSectionProps {
  data: DeepPlaneFaceliftSectionData;
}

export default function DeepPlaneFaceliftSection({
  data,
}: DeepPlaneFaceliftSectionProps) {
  return (
    <section id="DeepPlaneFaceliftSection" className="py-[35px] bg-[#b1a3a426]">
      <h2
        className="font-freightBig text-[40px] text-[#332f2c] leading-[1.1] text-center mb-[35px] max-w-[1080px] mx-auto"
        dangerouslySetInnerHTML={sanitizeHTML(
          data?.heading?.title || data?.heading?.text || ""
        )}
      />
      <div className="max-w-[1080px] mx-auto px-6 grid md:grid-cols-2 md:gap-[5.5%] justify-between h-auto">
        <div
          className="[&>h3]:font-freightBig [&>h2]:font-freightBig [&>h2]:text-3xl [&>h3]:text-2xl [&>h3]:leading-none [&>h3]:mb-4 space-y-4 h-auto prose prose-ul:list-disc [&>ul]: prose-ul:pl-4 [&>*]:font-aileron [&>ul]:text-black [&>ul>li::marker]:text-black [&>blockquote]:border-black [&_p]:before:content-[''] [&_p]:after:content-['']"
          dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")}
        />
        <div className="relative h-auto flex flex-col gap-[35px]">
          <iframe
            className="w-full h-[315px] rounded-xl"
            loading={"lazy"}
            title={data?.video?.embed_title || ""}
            src={data?.video?.embed_url || ""}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            width="560"
            height="315"
            sandbox="allow-same-origin allow-scripts allow-presentation"
          />
          {data?.image && (
            <Image
              src={getReadableImageUrl(
                data.image.image_file.image.id,
                data.image.image_file.image.filename_download
              )}
              alt={data.image.alt}
              width={0}
              height={0}
              sizes="100vw"
              className="w-auto h-fit object-cover rounded-xl"
              quality={95}
              loading={"lazy"}
            />
          )}
        </div>
      </div>
      {data?.button && (
        <div className="flex justify-center mt-[35px]">
          <Button asChild>
            <Link href={data?.button?.href || ""}>
              {data?.button?.label || ""}
            </Link>
          </Button>
        </div>
      )}
    </section>
  );
}
