import Image from "next/image";
import Button from "../ui/button";
import Link from "@/components/ui/Link";
import { sanitizeHTML } from "@/lib/utils";
import { getReadableImageUrl } from "@/lib/directus";

interface FacialSurgeonData {
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  subheading2?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
  image?: {
    alt?: string;
    image_file?: {
      image?: {
        id?: string;
        filename_disk?: string;
      };
    };
  };
  button?: {
    label?: string;
    href?: string;
  };
}

export const fields = [
  "heading.text",
  "heading.title",

  "subheading.text",
  "subheading.title",
  "subheading2.text",
  "subheading2.title",

  "text.text",
  "image.alt",
  "image.image_file.image.id",
  "image.image_file.image.filename_download",
  
  "button.label",
  "button.href"
] as const;

export default function FacialSurgeon({ data }: { data?: FacialSurgeonData }) {
  return (
    <section className="py-12">
      <div className="container">
        <span className="text-sm font-bold">{data?.subheading?.title || ""}</span>
        <h2 className="text-2xl md:text-4xl font-freightBig">
          {data?.heading?.title || ""}
        </h2>
        <span className="text-lg mb-4 block">
          {data?.subheading2?.title || ""}
        </span>
        <div className="flex flex-col md:flex-row gap-10 lg:gap-20 items-start w-full">
          <Image
            src={getReadableImageUrl(data?.image?.image_file?.image?.id || "", data?.image?.image_file?.image?.filename_download || "")}
            alt={data?.image?.alt || ""}
            width={616}
            height={639}
            className="md:w-2/5 w-full"
            quality={95}
            loading={"lazy"}
          />
          <div className="text-lg md:text-xl space-y-8 md:space-y-16 w-full md:w-3/5">
            <div className="prose max-w-none" dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || "")} />
            {data?.button?.href && (
              <div className="flex justify-start mt-8">
                <Button asChild>  
                  <Link href={data?.button?.href || ""}>{data?.button?.label || ""}</Link>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
