import { tableData } from "./constants";
import { sanitizeHTML } from "@/lib/utils";

interface DeepPlaneVsStandardData {
  heading?: {
    text?: string;
    title?: string;
  };
  subheading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
}

interface DeepPlaneVsStandardProps {
  data: DeepPlaneVsStandardData;
}

export const fields = [
  "heading.text",
  "heading.title",
  "subheading.text",
  "subheading.title",
  "text.text"
] as const;

export default function DeepPlaneVsStandard({
  data,
}: DeepPlaneVsStandardProps) {
  return (
    <section id="deep-plane-vs-standard" className="bg-[#f9f9f9] py-8 md:py-14">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-[50px] font-freightBig text-center text-[#655e5d] mb-6" 
          dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.text || data?.heading?.title || '')}
        />
        <p className="text-center text-secondary mb-8"
          dangerouslySetInnerHTML={sanitizeHTML(data?.subheading?.text || data?.subheading?.title || '')}
        />
        <div className="overflow-x-auto max-w-[1080px] mx-auto" dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')} />
      
      </div>
    </section>
  );
}
